import i18n from '@/libs/i18n';

export default [
    {
        'id': 15,
        'name': i18n.tc('timeout.15minutes'),
    },

    {
        'id': 30,
        'name': i18n.tc('timeout.30minutes'),
    },

    {
        'id': 60,
        'name': i18n.tc('timeout.1hour'),
    },
    {
        'id': 120,
        'name': i18n.tc('timeout.2hour',),
    },
    {
        'id': 180,
        'name': i18n.tc('timeout.3hour'),
    },
    {
        'id': 300,
        'name': i18n.tc('timeout.5hour'),
    },
    {
        'id': 480,
        'name': i18n.tc('timeout.8hour'),
    },
    {
        'id': 720,
        'name': i18n.tc('timeout.12hour'),
    },
    {
        'id': 1440,
        'name': i18n.tc('timeout.24hour'),
    },
];
